import { publisher as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

/**
 * Get all */
export const listPublishers = ({
  query = {},
  onSuccess = null
}: {
  query: any
  onSuccess?: () => void
}) => ({
  type: types.list,
  payload: service.list(query),
  onSuccess
})

/**
 * Get all report publishers
 */
export const listReportPublishers = ({
  query = {},
  onSuccess = null
}: {
  query: any
  onSuccess?: () => void
}) => ({
  type: types.listReport,
  payload: service.listReport(query),
  onSuccess
})

/**
 * Handle page */
export const setPagePublisher = (value: number) => ({
  type: types.page,
  payload: value
})

/**
 * Handle items por page */
export const setPerPagePublisher = (value: number) => ({
  type: types.perPage,
  payload: value
})

/**
 * Get publisher info
 */
export const getPublisher = ({
  id,
  params = {}
}: {
  id: string
  params: object
}) => ({
  type: types.getPublisher,
  payload: service.get({ id, params })
})

export default {
  listPublishers,
  listReportPublishers,
  getPublisher
}
