const prefix = '@retailMedia/industry/ad'

export const AD_PATCH = `${prefix}/PATCH`

export const CPC_PATCH = `${prefix}/PATCH`

export const CPM_PATCH = `${prefix}/CPM_PATCH`

export const ADD_BANNER_POST = `${prefix}/ADD_BANNER_POST`

export const types = {
  patch: AD_PATCH,
  cpc_patch: CPC_PATCH,
  cpm_pactch: CPM_PATCH,
  add_banner_post: ADD_BANNER_POST
}
