import { t } from 'i18next'

import { ad as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

export const patchAd = ({
  body,
  id
}: {
  id: string
  body: { active: boolean }
}) => ({
  type: types.patch,
  payload: service.patch({ body, id })
})

export const cpcPatch = ({
  body,
  id,
  onSuccess
}: {
  id: string
  body: { cpc: number | null }
  onSuccess?: () => void
}) => ({
  values: { id, body },
  type: types.cpc_patch,
  payload: service.cpcPatch({ body, id }),
  successFeedbackMsg: t('rm:actions.industry.ad.updateCPC'),
  onSuccess
})

export const cpmPatch = ({
  body,
  id,
  onSuccess
}: {
  id: string
  body: { cpm: number | null }
  onSuccess?: () => void
}) => ({
  values: { id, body },
  type: types.cpm_pactch,
  payload: service.cpmPatch({ body, id }),
  successFeedbackMsg: t('rm:actions.industry.ad.updateCPM'),
  onSuccess
})

export const addBanner = ({ body, onSuccess }) => ({
  type: types.add_banner_post,
  payload: service.addBannerPost({ body }),
  requestInBackground: true,
  titleSuccessFeedBack: t('rm:actions.campaign.add.banners.success.title'),
  successFeedbackMsg: t('rm:actions.campaign.add.banners.success.message'),
  errorFeedbackMsg: t('rm:actions.campaign.add.ads.error'),
  onSuccess
})

export default {
  patchAd,
  cpcPatch,
  cpmPatch
}
